import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Banner from './Banner/Banner';
import reportWebVitals from './reportWebVitals';
import Description from "./Description/Description";
import Skills from "./Skills/Skills";
import Videos from "./Videos/Videos";
import Contact from "./Contact/Contact";
import CustomNavbar from "./CustomNavbar";
import SoundRecords from "./SoundRecords/SoundRecords";

ReactDOM.render(
    <React.StrictMode>
            <CustomNavbar/>
            <Banner/>
            <Description/>
            <div className={"leftSkewedSeparator blueSection"}/>
            <Skills/>
            {/*<Realisations/>*/}
            <div className={"rightSkewedSeparator purpleSection"}/>
            <SoundRecords/>
            <Videos/>
            <div className={"leftSkewedSeparator darkSection"}/>
            <Contact/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
